<template>
  <div>
    <ul class="biz-breadcrumb" v-if="!isRegistrationWizard">
      <li>
        <router-link to="/supplier/manage">{{$t('SYSTEM_MANAGEMENT.TITLE')}}</router-link>
      </li>
      <li>
        <router-link to="/supplier/manage/user-info">{{$t('USERS.TITLE')}}</router-link>
      </li>
      <li><a>{{$t('COMMON.EDIT')}}</a></li>
    </ul>
    <div class="page">
      <div class="page-title">
        <h1>{{$t('USERS.TITLE')}}</h1>
        <p>{{$t('USERS.DESC')}}</p>
      </div>
      
      <ValidationObserver v-slot="{ handleSubmit }" ref="form">
        <form @submit.prevent="handleSubmit(save)">
          <UserInfo 
            :main="mainUser" 
            @mainInput="mainUser = $event"
            :subs="subs"
            @subsInput="subs = $event"
            :isRegistrationWizard="isRegistrationWizard"
          />
          <div class="text-center mt-4">
            <j-button variant="primary" buttonType="submit">
              {{isRegistrationWizard?$t('COMMON.NEXT'):$t('COMMON.SAVE')}}
            </j-button>
            <br />
            <j-button variant="light" class="mt-2" v-if="!isRegistrationWizard" to="/supplier/manage/user-info">
              <font-awesome-icon icon="arrow-left"></font-awesome-icon>
              {{$t('COMMON.CANCEL')}}
            </j-button>
            <j-button variant="light" class="mt-2" v-if="isRegistrationWizard" to="/initiate/registration/company-info">
              <font-awesome-icon icon="arrow-left"></font-awesome-icon>
              {{$t('COMMON.GO_BACK')}}
            </j-button>
          </div> 
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<style scoped lang="scss">
ul.duplicate-emails {
  padding-inline: 0;
  margin: 0;
  list-style-position: inside;
  max-height: 70vh;
  overflow-y: auto;

  > li {
    word-wrap: break-word;
    word-break: break-all;
  }
}
</style>
<script>
  import UserInfoModel from '@/model/UserInfo';
  import InitModel from '@/model/Init';
  
  import UserInfo from '@/components/register/UserInfo.vue';
  
  export default {
    props: ["isRegistrationWizard" , "notSave", "formData"],
    components: { UserInfo },
    data: () => ({
      mainUser: {},
      subs: [],
      originalLoginUserRolesJson: ""
    }),
    mounted() {
      Promise.all([
        UserInfoModel.getMain(this.$store.state.user.companyCode), //results[0]
        UserInfoModel.getSubs(this.$store.state.user.companyCode), //results[1]
      ]).then((results) => {
        
        this.mainUser = results[0].data;
        this.subs = results[1].data;
        if(this.formData){
          if(Object.keys(this.formData.main).length > 0)
            this.mainUser   = this.formData.main;
          if(Object.keys(this.formData.subs).length > 0)
            this.subs   = this.formData.subs;
        }
        //if(this.subs.length <= 0) this.addSub();
        InitModel.getRoles(this.$store.state.user.status.spotSupplier).then(result => {
          if(!this.mainUser.roles){
            this.mainUser.roles = [];
          }
          const roles = result.data.filter(role => !this.mainUser.roles.some(m => m.roleCode === role.roleCode));
          this.mainUser.roles.push(...roles);
          this.mainUser.roles.forEach(role => {
            role.checked = true;
          });
          this.subs.forEach(sub => {
            var subRoles = result.data.filter(role => !sub.roles.some(s => s.roleCode === role.roleCode)).map(a => {return { ... a }});
            sub.roles.push(...subRoles)
          })
        });
      this.subs.forEach(s => {
        if(s.email === this.$store.state.user.displayCode) {
          this.originalLoginUserRolesJson = JSON.stringify(s.roles);
        }
      })
        if(this.$store.state.fromPortal){
          InitModel.confirm(this.$store.state.publicAuthCode).then(result => {
            let pbInfo = result.data;
            this.$set(this.mainUser, 'code', pbInfo.userCode);
            this.$set(this.mainUser, 'email', pbInfo.email);
            this.$set(this.mainUser, 'preRegistered', true);
            this.$set(this.mainUser, 'publicAuthCode', this.$store.state.publicAuthCode);
          });
        }
      });
    },
    methods: {
      save() {
        let subs = [].concat(this.subs.filter(s => s.organizationName && s.name && s.companyMobilePhone && s.email));

        if (this.notSave) {
          this.$emit('onSave', {
            main: this.mainUser,
            subs: subs
          });
        } else {
          UserInfoModel.save(this.$store.state.user.companyCode, {
            main: this.mainUser,
            subs: subs
          }).then(() => {
            this.updateRole();
            this.$router.push({name: "UserInfo"})
            this.$successMessage(this.$t('USERS.SUCCESS_MESSAGE'));
          }).catch(({response}) => {
            if (response.data && response.data.errorCode === 'duplicate-emails') {
              const h = this.$createElement,
                  msg = [
                    h('div', {}, this.$t('COMMON.USER_DUPLICATE_WARNING')),
                    h('ul', {class: 'duplicate-emails'}, response.data.emails.map(email => h('li', {}, email)))
                  ]
              this.$errorMessage(msg, {noAutoHide: true})
            } else {
              this.$errorMessage(undefined)
            }
          });
        }
      },
      updateRole(){
        if(this.originalLoginUserRolesJson.length > 0){
          let loginUserRolesUpdated = {};
          this.subs.forEach(s => {
            if(s.email === this.$store.state.user.displayCode) {
              if(this.originalLoginUserRolesJson !== JSON.stringify(s.roles)){
                loginUserRolesUpdated = s.roles
              }
            }
          })
          if(loginUserRolesUpdated.length > 0){
            let commitRoles = loginUserRolesUpdated.filter(role => role.checked === true)
            this.$store.commit("setRoles", commitRoles)
          }
        }
      }
    }
  }
</script>
